import React, { Component } from 'react';

import NavbarPage from "../../components/Navbar/Navbar_Page";

class AdminDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems : [
                { id: 1 , idnm : "home", navheading: "Home" },
                { id: 2 , idnm : "services", navheading: "Services" },
                { id: 3 , idnm : "features", navheading: "Features" },
                { id: 4 , idnm : "pricing", navheading: "Pricing" },
                { id: 5 , idnm : "team", navheading: "Team" },
                { id: 6 , idnm : "blog", navheading: "Blog" },
                { id: 7 , idnm : "contact", navheading: "Contact" }
            ],
            navClass : ""
        };
    }

    render() {
        return (
            <React.Fragment>
                <h1>Here Admin</h1>
            </React.Fragment>
        );
    }
}

export default AdminDashboard;