import React, { Component } from "react";

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.closeModal.bind(this)
    this.callModal.bind(this);
  }

  callModal = () => {
    this.setState({ isOpen: true })
  };

  closeModal = () => {
    this.setState({ isOpen: false })
  };

  render() {
    return (
      <React.Fragment>
        <div className="home-footer">
          <a href="https://twitter.com/proof_of_fun" target="_blank" className="p-2">
            <i className="mdi mdi-twitter"></i>
          </a>

          <a href="https://discord.gg/proofoffun" target="_blank" className="p-2">
            <i className="mdi mdi-discord"></i>
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
