import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  Container,
  Collapse,
  Button,
} from "reactstrap";

import ScrollspyNav from "./scrollSpy";

class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
      navClass : ""
    };
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    console.log("scrollup",scrollup)
    if (scrollup > 50) {
      this.setState({ navClass : "is-sticky"});
    } else {
      this.setState({ navClass : ""});
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    console.log("clicked");
  }

  render() {
    return (
      <React.Fragment>
          <div id="navbar" className={this.state.navClass}>
            <nav
              className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
              style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
            >
              <Container fluid>
                <NavbarBrand className="logo text-uppercase" href="/">
                  <img src="assets/images/branding/logo.png" alt="Proof of Fun" />
                </NavbarBrand>

                <div className="nav-button ms-auto">
                  {/* <Link to={`/login`}> */}
                  <Link>
                    <Button
                      color="none"
                      type="button"
                      className="btn btn-primary navbar-btn btn-rounded waves-effect waves-light"
                      disabled
                      style={{ opacity: '0.6' }}
                    >
                      Launch
                    </Button>
                  </Link>
                  <div className="mt-2" style={{ color: 'rgb(181 181 181)', fontSize: '11px', textAlign: 'center', opacity: '0.7' }}>Coming Soon</div>
                </div>
              </Container>
            </nav>
            </div>
      </React.Fragment>
    );
  }
}

export default NavbarPage;
