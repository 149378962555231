import React, { Component } from "react";
import { Link } from "react-router-dom";
// Formik validation
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FacebookLoginButton, GoogleLoginButton, TwitterLoginButton } from "react-social-login-buttons";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      userName: "",
      password: ""
    };
  };

  handleSubmit = (values) => {
    console.log("values: ", values);
  };

  render() {
    return (
      <React.Fragment>
        <div className="account-home-btn d-none d-sm-block">
          <Link to="/" className="text-white">
            <i className="mdi mdi-home h1"></i>
          </Link>
        </div>

        <section className="bg-account-pages vh-100">
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={5}>
                    <Card className="account-card">
                      <CardBody>
                        <div className="text-center mt-3">
                          <h3 className="fw-bold">
                            <Link
                              to="/"
                              className="text-dark text-uppercase account-pages-logo"
                            >
                              <img src="assets/images/branding/logo2.png" className="secondary-logo" alt="Proof of Fun" />
                            </Link>
                          </h3>
                          <p className="text-muted">
                            Sign up to continue to PROOF OF FUN.
                          </p>
                        </div>
                        <div className="p-3">

                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                              email: (this.state && this.state.email) || "",
                              userName: (this.state && this.state.userName) || "",
                              password: (this.state && this.state.password) || "",
                              confirmPassword: (this.state && this.state.confirmPassword) || "",
                            }}
                            validationSchema={Yup.object().shape({
                              email: Yup.string().required("Please Enter Your Email"),
                              userName: Yup.string().required("Please Enter Your User Name"),
                              password: Yup.string().required("Please Enter Your Password"),
                              confirmPassword: Yup.string().required("Please Enter Your Confirm Password"),
                            })}
                            onSubmit={values => {
                              console.log(values);
                              window.location.pathname = '/admin/games';
                              // <Navigate to={'/admin/dashboard'} replace={true} />
                            }}
                          >
                            {({ errors, status, touched }) => (
                              <Form>
                                <div className="mb-3">
                                  <label htmlFor="email" className="form-label">
                                    Email
                                  </label>
                                  <Field
                                    name="email"
                                    type="email"
                                    className={
                                      "form-control" +
                                      (errors.userName && touched.userName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                  <label htmlFor="userName" className="form-label">
                                    Username
                                  </label>
                                  <Field
                                    name="userName"
                                    type="userName"
                                    className={
                                      "form-control" +
                                      (errors.userName && touched.userName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="userName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                  <label htmlFor="password" className="form-label">
                                    Password
                                  </label>
                                  <Field
                                    name="password"
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                  />

                                  <label htmlFor="password" className="form-label">
                                    Confirm Password
                                  </label>
                                  <Field
                                    name="password"
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="d-grid mt-3">
                                  <button type="submit" className="btn btn-primary" style={{ height: '50px' }}>Signup</button>
                                </div>

                                <FacebookLoginButton onClick={() => alert("Hello")} style={{ width: '100%', margin: '5px 0 10px 0', fontSize: '14px' }} />

                                <GoogleLoginButton onClick={() => alert("Hello")} style={{ width: '100%', margin: '5px 0 10px 0', fontSize: '14px' }} />

                                <TwitterLoginButton onClick={() => alert("Hello")} style={{ width: '100%', margin: '5px 0 10px 0', fontSize: '14px' }} />

                                <div className="mt-4 mb-0 text-center">
                                  <Link to="/login" className="text-dark"><i
                                    className="mdi mdi-login"></i> Login</Link> <br />
                                  <Link to="/password-forget" className="text-dark"><i
                                    className="mdi mdi-lock"></i> Forgot your password?</Link>
                                </div>
                              </Form>
                            )}
                          </Formik>

                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Signup;
