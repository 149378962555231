import React, { Component } from 'react';

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from './section';
import Footer from './footer';

class Index9 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems : [
                { id: 1 , idnm : "home", navheading: "Home" },
            ],
            navClass : ""
        };
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <React.Fragment>
                {/* Importing Navbar */}
                <NavbarPage />

                {/* section */}
                <Section/>

                <Footer />

                <style>
                {`
                    #gradient-canvas {
                        display: block !important;
                    }
                `}
                </style>
            </React.Fragment>
        );
    }
}

export default Index9;