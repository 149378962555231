import React, { useState, useCallback, useEffect } from "react";
import axios from 'axios';
import { Container, Row, Col } from "reactstrap";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Face from "./face.svg";

const SITE_KEY = "6Le9s4QkAAAAAIJFpu4yv-uQkdfO14R8Z0oiYFfx";

const toastError = (message) => toast.error(message, {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
});

const Section = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  const handleOnSubmit = e => {
    e.preventDefault();
    if (email) {
      if (email.toLowerCase().match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
        setLoading(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
            submitData(token);
          });
        });
      } else {
        const notify = (message) => toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        toastError("Please enter a real email address!");
      };
    } else { 
      toastError("Please enter an e-mail address!");
    }
  }

  const submitData = token => {
    const notify = (message) => toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    const payload = {
      "email": email,
      "response": token
    }

    axios.post(`https://waitlist.pof.games/waitlist`, payload)
    .then(res => {
      console.log("res: ", res);
      console.log("res.data: ", res.data);
      notify(res.data);

      setEmail('');
      setLoading(false);
      setResponse(response);
    }).catch(err => {
      console.log(err);
    })

    // call a backend API to verify reCAPTCHA response
    // fetch('https://waitlist.pof.games/waitlist', {
    //   method: 'POST',
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify({
    //     "email": email,
    //     "response": token
    //   })
    // }).then((response) => {
    //   console.log("response: ", response.text());
    //   debugger;
    //   setEmail('');
    //   setLoading(false);
    //   setResponse(response);
    //   return response.text();
    // }).catch(error => {
    //   console.log("error: ", error);
    //   setLoading(false);
    // });
  }
 

  return (
    <React.Fragment>
      <section className="section bg-home vh-100 position-relative" id="home">
        {/* <div className="bg-overlay"></div> */}

        <div className="">
          <div className="">
            <Container fluid className="slidero">
              <Row>
                <Col
                  lg={{ size: 8, offset: 2 }}
                  className="text-white text-center"
                >
                  {/* <h1 className="home-title animate__animated animate__heartBeat"></h1> */}
                  <h1 className="home-title">
                    STEP UP YOUR GAME
                  </h1>
                  <p className="pt-3 home-desc" style={{ fontSize: '24px' }}>
                    Web3 Expansion Pack for Games
                  </p>

                  <form className="early-access-form mt-4 mt-md-5" onSubmit={handleOnSubmit}>
                    <input type="email" name="email" required className="form-control" onChange={e => setEmail(e.target.value)} value={email} />
                    <button type="submit" required className="btn btn-primary" disabled={loading}>{loading ? 'Submitting...' : 'Get Early Access'}</button>
                  </form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <div className="wrapper-face-img">
          {/* <img src={Face} className="face-img animate__animated animate__slideInRight" /> */}
          <img src={Face} className="face-img" />
        </div>
        {/* Render ModalSection Component for Modal */}
        {/* <ModalSection channel="youtube" videoId="P5OY98ttAOk" closeModal={this.closeModal} isOpen={this.state.isOpen} /> */}
      </section>

      <ToastContainer />
    </React.Fragment>
  );

};

export default Section;
