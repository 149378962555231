import React from "react";

// Landing Page
import Index from "./pages/Index/Index";

//Auth Pages
import Signup from "./pages/Auth/Signup";
import Login from "./pages/Auth/Login";
import PasswordForget from "./pages/Auth/PasswordForget";
import AdminDashboard from "./pages/Admin/AdminDasboard";

const routes = [
  // Auth
  { path: "/login", component: <Login /> },
  { path: "/sign-up", component: <Signup /> },
  { path: "/password-forget", component: <PasswordForget /> },

  // Landing Page
  { path: "/", component: <Index /> },

  // Admin
  { path: "/admin/dashboard", component: <AdminDashboard /> },

];

export default routes;
